<template>
  <Modal 
    :activeModal="activeModal" 
    :widthModal="450"
    @closeModal="closeModal"
  >
    <v-card>
      <v-toolbar flat height="40px" class="modal-cadastro-toolbar">
        Bilhete Prêmiado
        <v-spacer></v-spacer>
        <v-icon @click="closeModal" class="modal-cadastro-close">close</v-icon>
      </v-toolbar>

      <v-container fluid class="pb-0">
        <v-col>
          <v-card>
            <v-card-text class="pb-0">
              <v-row>
                <v-col cols="12" class="pa-3 pt-0 pb-1 d-flex justify-space-between">
                  <span>Código Bilhete:</span> <strong> {{ bilhete.codigoId }}</strong>
                </v-col>
                <v-col cols="12" class="pa-3 pt-0 pb-1 d-flex justify-space-between">
                  <span>Quantidade de Aposta:</span> <strong> {{ bilhete.ApostasCount }} </strong>
                </v-col>
                <v-col cols="12" class="pa-3 pt-0 pb-1 d-flex justify-space-between">
                  <span>Nome Cliente:</span> <strong> {{ bilhete.NomeCliente }} </strong>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>

          <v-card class="mt-2">
            <v-card-text class="pb-0">
              <v-row>
                <v-col cols="12" class="pa-3 pt-0 pb-1">
                  <strong> Confirmar o valor do prêmio</strong>
                </v-col>
                <v-col cols="12" class="pa-3 mt-3 pt-0 pb-1 d-flex justify-space-between">
                  <v-currency-field
                    v-model="valorPremio"
                    label="Valor do Prêmio"
                    prefix="R$"
                    outlined
                    dense
                  />
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>

      </v-container>
      <v-card-actions class="modal-cadastro-footer">
        <v-btn :color="variables.colorPrimary" @click="closeModal" class="br-btn br-btn-cancelar">Não</v-btn>
        <v-btn type="submit" :color="variables.colorPrimary" @click.prevent="premiarBilhete" class="br-btn" :loading="loading">{{ bilhete.winner ? 'Cancelar' : 'Confirmar' }}</v-btn>
      </v-card-actions>
    </v-card>
  </Modal>
</template>

<script>
import { mapActions } from 'vuex'
import { validationMixin } from 'vuelidate'
// import { required } from 'vuelidate/lib/validators'
import Events from '@/core/service/events'
import variables from '@/assets/styles/helpers/_variables.scss'
import { errorSnackbar } from '@/core/service/utils'
// import { each, size } from 'lodash'
// import validacao from './validacao'

// eslint-disable-next-line
const namespaceStore = ''

export default {
  name: 'ModalJogos',
  mixins: [validationMixin],
  components: {
    Modal: () => import('@/views/components/modal')
  },

  props: {
    activeModal: {
      type: Boolean,
      default: false
    },
    bilhete: {
      type: Object,
      default: () => {}
    }
  },

  data: () => ({
    loading: false,
    nameRevenda: '',
    valorPremio: '',
    dadosJogos: []
  }),
  
  computed: {
    
    // ...validacao,
    variables: () => variables,
    // valorPremio: this.bilhete.PossivelRetorno
  },

  watch: {
    'bilhete.PossivelRetorno' (val) {
      this.valorPremio = val
    }
  },

  methods: {
    ...mapActions('bilhetes', ['bilhetePremiado']),

    closeModal () {
      this.$emit('closeModal')
    },

    premiarBilhete () {
      let { bilhete_id, resultId, winner } = this.bilhete
      winner = winner ? 0 : 1
      this.loading = true
      this.bilhetePremiado({ 
        bilheteId: bilhete_id,
        resultId,
        winnerStatus: winner,
        valuePrice: this.valorPremio
      })
      .then(() => {
        this.$emit('getBilhetes')
      })
      .catch((error) => {
        errorSnackbar(error.message)
      })
      .finally(() => {
        this.loading = false
      })
    }

  },

  beforeDestroy () {
    Events.$off('ganhadores::modal::baixar')
  }
}
</script>

<style lang="scss">
  @import '~@/assets/styles/components/formularios';
  @import '~@/assets/styles/components/modal-cadastro';
</style>